<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
<!--                <b-button variant="primary" :to="{ name: 'BranchNew' }">-->
<!--                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo-->
<!--                </b-button>-->
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="accountsRp.itemsPerPage" id="perPageSelect" size="sm" :options="accountsRp.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>

            <!--<b-col sm="7" md="6" class="my-1">
                <b-input-group prepend="Filtrar: ">
                    <b-input v-model="branches.filter" debounce="300"></b-input>
                </b-input-group>
            </b-col>-->
        </b-row>
        <b-table :items="loadData" :fields="accountsRp.fields" :filter="accountsRp.filter" :current-page="accountsRp.currentPage"
                 :per-page="accountsRp.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="accountsRp.sortBy" :sort-desc="accountsRp.sortDesc"
                 ref="accountsRpTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in accountsRp.fields" :key="field.key"
                          :class="accountsRp.filters[index] && accountsRp.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="accountsRp.filters[index]">
                            <b-col v-if="accountsRp.filters[index].inputType === 'Select'">
                                <b-select v-model="accountsRp.filters[index].value">
                                    <template v-if="'selectOptions' in accountsRp.filters[index]">
                                        <b-select-option v-for="option in accountsRp.filters[index].selectOptions" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </b-select-option>
                                    </template>
                                    <template v-else>
                                        <b-select-option value="">Todos</b-select-option>
                                        <b-select-option value="BALANCE">Por saldar</b-select-option>
                                        <b-select-option value="PAID">Pagada</b-select-option>
                                        <b-select-option value="CANCELLED">Cancelada</b-select-option>
                                    </template>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="accountsRp.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="accountsRp.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(folio)="row">
                <p><router-link :to="{ name: 'AccountRpShow', params: { id: row.item.id }}" target="_blank">{{ row.item.folio }}</router-link></p>
            </template>

            <template #cell(orderFolio)="row">
                <p v-if="row.item.objectType === 'BusinessOrder'"><router-link :to="{ name: 'BusinessOrderShow', params: { id: row.item.objectId }}" target="_blank">{{ row.item.orderFolio }}</router-link></p>
                <p v-else-if="row.item.objectType === 'ServiceOrder'"><router-link :to="{ name: 'ServiceOrderShow', params: { id: row.item.objectId }}" target="_blank">{{ row.item.orderFolio }}</router-link></p>
                <p v-else-if="row.item.objectType === 'Expense'"><router-link :to="{ name: 'ExpenseShow', params: { id: row.item.objectId }}" target="_blank">{{ row.item.orderFolio }}</router-link></p>
            </template>

            <template #cell(type)="row">
                <p v-if="row.item.type === 'RECEIVABLE'">Por cobrar</p>
                <p v-if="row.item.type === 'PAYABLE'">Por pagar</p>
            </template>

            <template #cell(amount)="row">
                <p>$ {{ formatCurrency(row.item.amount) }}</p>
            </template>

            <template #cell(paidReceiveAmount)="row">
                <p>$ {{ formatCurrency(row.item.paidReceiveAmount) }}</p>
            </template>

            <template #cell(balance)="row">
                <p>$ {{ formatCurrency(row.item.balance) }}</p>
            </template>

            <template #cell(status)="row">
                <b-badge variant="warning" v-if="row.item.status === 'BALANCE'" class="p-1">Por saldar</b-badge>
                <b-badge variant="primary" v-if="row.item.status === 'PAID'" class="p-1">Pagada</b-badge>
                <b-badge variant="danger" v-if="row.item.status === 'CANCELLED'" class="p-1">Cancelada</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'AccountRpShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ accountsRp.totalRows }} registro<template v-if="accountsRp.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="accountsRp.currentPage" :total-rows="accountsRp.totalRows"
                                      :per-page="accountsRp.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { accountRpListUrl } from '@routes';
import * as constants from '@constants';
import tableStateMixin from '../../mixins/tableState';
import { formatCurrency } from '../../js/utilities';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            accountsRp: {
                fields: [
                    // { key: 'id', label: 'id', sortable: true },
                    { key: 'createdDate', label: 'Fecha', sortable: true },
                    { key: 'folio', label: 'Folio', sortable: true },
                    { key: 'type', label: 'Tipo', sortable: true },
                    { key: 'orderFolio', label: 'Concepto', sortable: true },
                    { key: 'businessEntitylegalName', label: 'Cliente/Proveedor', sortable: true },
                    { key: 'amount', label: 'Importe', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    { key: 'paidReceiveAmount', label: 'Pagado', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    { key: 'balance', label: 'Saldo', sortable: true, thStyle: 'width: 8%', class: 'text-right' },
                    { key: 'status', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: 'DATE_FORMAT(arp.created, \'%d/%m/%Y %H:%i\')', type: 'STRING', value: '', format: '' },
                    { column: 'arp.folio', type: 'STRING', value: '', format: '' },
                    { column: 'arp.type', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'RECEIVABLE', text: 'Por cobrar' }, { value: 'PAYABLE', text: 'Por pagar' }]
                    },
                    { column: "IF(arp.object_type = 'Expense', CONCAT('Gasto general ', arp.order_folio), arp.order_folio)", type: 'STRING', value: '', format: '' },
                    { column: 'arp.business_entity_legal_name', type: 'STRING', value: '', format: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    // { column: 'arp.monto', type: 'STRING', value: '', format: '' },
                    // { column: 'arp.paid_receive_amount', type: 'STRING', value: '', format: '' },
                    // { column: 'arp.balance', type: 'STRING', value: '', format: '' },
                    { column: 'arp.status', type: 'STRING', value: '', format: '', inputType: 'Select' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'arp.created',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Cuentas',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.accountsRp, this.tableState);
    },
    methods: {
        formatCurrency: formatCurrency,
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.accountsRp.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(accountRpListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.accountsRp.totalRows = response.data.totalRows;
                        return response.data.accountsRp;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        tableContextChanged(context) {
            this.accountsRp.sortBy = context.sortBy;
            this.accountsRp.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.accountsRp);
        }
    },
    watch: {
        'accountsRp.filters': {
            handler() {
                this.$refs.accountsRpTable.refresh();
                this.saveTableState(this.$route.name, this.accountsRp);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.accountsRp.totalRows === 0 ? 0 : (this.accountsRp.currentPage - 1) * this.accountsRp.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.accountsRp.currentPage - 1) * this.accountsRp.itemsPerPage;

            return (this.accountsRp.totalRows - offset) < this.accountsRp.itemsPerPage ? this.accountsRp.totalRows : offset + this.accountsRp.itemsPerPage;
        }
    }
};
</script>

<style scoped>

</style>